import { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button } from "@mui/material";

import './Hero.css'
import { Link } from 'react-router-dom';
import screenshot from './demo.png'
import pbs from './poweredbystrava.svg'


const hello = async () => {
    const response = await fetch('https://xuruz0szwg.execute-api.us-east-1.amazonaws.com');
    const data = await response.json();
    return data;
}

function GradientBG(props) {
    const [animationClass, setAnimationClass] = useState('play');
    
    const changeState = () => {
        if (animationClass === 'play') {
            setAnimationClass('pause');
        } else {
            setAnimationClass('play');
        }
    }
        return (
        <Box className={animationClass}>
            {props.children}
        </Box>
        )

    }
    


export default function Hero(props) {
    const [helloMsg, setHelloMsg] = useState(null);

    useEffect(() => {
        hello().then((data) => {
            setHelloMsg(data);
        });
        
    }, []);
    

    return <Grid2 item xs={12} md={6}>
            <Box sx={{
            display: 'grid',
            minHeight: '20vh',
            alignItems: 'center',
            mt: '50px'}}>
            <GradientBG>
                <Grid2 container
                direction="row"
                alignItems="center"
                    justifyContent="center">   
                    <Grid2 item xs={12}>
                        <div className="play">
                        <Typography  variant="h1" sx={{ 
                            letterSpacing: '-3px',
                            fontWeight: 'bold', 
                            mb: 2}}>
                            ActiveTracks
                        </Typography>
                        </div>
                    </Grid2>
                </Grid2>
            </GradientBG>
        
        </Box>
        <Grid2 container direction='column' alignItems='center'>
            <Grid2 item xs={12}>

            <Button 
                variant="contained"
                component={Link}
                to="/signup"
                sx={{ 
                    width: {xs: '66%', md: '33%'},
                    color: 'white', 
                    bgcolor: 'primary.main', 
                    fontWeight: 'bold', 
                    fontSize: {xs: '1.5rem', s: '1.5rem'},
                    boxShadow: 5}}>
                Get started
            </Button>
            </Grid2>
            <Grid2 item xs={12}>
            <Button 
                variant="contained"
                component={Link}
                to="/signin"
                sx={{ 
                    width: {xs: '33%', md: '33%'},
                    bgcolor: 'white', 
                    color: 'primary.main', 
                    fontWeight: 'bold', 
                    fontSize: {xs: '1.5rem', s: '1.5rem'},
                    mt: 2,
                    boxShadow: 5}}>
                Log in
            </Button>
            </Grid2>
        </Grid2>
            <Grid2 container direction='column' alignItems='center' mt={2} p={4}>
                <Typography variant="h3" >
                    ActiveTracks links your Strava and Spotify profiles to track the songs you listen to during your workouts and share them with your Strava followers.
                </Typography>
            </Grid2>
            <img src={screenshot} alt="demo" width="100%" />

            <img src={pbs} alt="powered by strava" width="33%" maxWidth="200px"/>
                
    </Grid2>
  }
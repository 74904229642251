import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import './App.css'
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";


const pages = ['activities', 'training', 'profile'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const style = {
  background: '#282c34'
};



const NavBar = () => {
  const [isAuth, setIsAuth] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  

  return (
    <AppBar position="static" style={style}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          




        </Toolbar>
      </Container>
      <Outlet />
    </AppBar>
    
  );
};
export default NavBar;
import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import spotify_icon from "./Spotify_Logo_RGB_White.png";

function SpotifyDisplay(props) {
  return (
    <>
      <Grid2 item xs={12}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2.5rem", s: "5rem" },
            letterSpacing: "-3px",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Step 1:
          <br />
          Sign in to Spotify
        </Typography>
      </Grid2>
      <Grid2 item xs={12}>
        <Button
          component={Link}
          onClick={() => (window.location.href = props.url)}
          sx={{
            borderRadius: 5,
            bgcolor: "spotify.main",
            '&:hover': {
              bgcolor: "#000",
            },
            height: "100%",
            display: "grid",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 3,
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              color="white"
              fontSize={{ xs: "1.5rem", s: "2rem" }}
              sx={{ mr: 1 }}
            >
              Connect to
            </Typography>
            <img src={spotify_icon} alt="Spotify" width={"100px"} />
          </div>
        </Button>
      </Grid2>
    </>
  );
}

export default function SetupSpotify() {
  const baseurl = "https://xuruz0szwg.execute-api.us-east-1.amazonaws.com";
  const token = localStorage.getItem("token");

  const [spotifyLink, setSpotifyLink] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();


  const navigate = useNavigate();

  useEffect(() => {}, []);

  const getSpotifyLink = async () => {
    const response = await fetch(`${baseurl}/v1/authspotify/url`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await response.json();
    setSpotifyLink(data[0].spotify_auth_url);
  };

  const checkIfToken = async () => {
    const response = await fetch(`${baseurl}/v1/hastoken?service=spotify`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data.has_token) {
        console.log("has spotify token");
        return true;
      } else {
        console.log("no spotify token");
        return false;
      }
    } else {
      console.log("error");
      return false;
    }
  };

  useEffect(() => {
    if (token) {
      getSpotifyLink();
    }
    checkIfToken().then((hasToken) => {
      if (hasToken) {
        navigate("/setup/strava");
      }
    });
    localStorage.removeItem("spot_auth");
  }, []);

  useEffect(() => {
    if (searchParams.get("code")) {
      fetch(`${baseurl}/v1/authspotify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          code: searchParams.get("code"),
        }),
      }).then((response) => {
        if (response.status === 200) {
          console.log("success");
          navigate("/setup/strava");
        }
      });
    }
  }, [searchParams]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 5,
        }}
      >
        <Grid2
          container
          spacing={2}
          sx={{ display: "flex", direction: "column", alignItems: "stretch" }}
        >
            <SpotifyDisplay url={spotifyLink} />
            
        </Grid2>
      </Box>
    </Container>
  );
}

import logo from './logo.svg';
import './App.css';
import {useTheme, ThemeProvider, createTheme} from '@mui/material/styles' 
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route} from "react-router-dom";


// my packages
import Navbar from './Navbar';
import Footer from './Footer';
import Hero from './Hero';
import Signup from './Signup';
import Signin from './Signin';
import PrivateRoute from './PrivateRoute';
import Finished from './Finished';
import SetupSpotify from './SetupSpotify';
import SetupStrava from './SetupStrava';
import About from './About';

// small change to push 
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#2b2b2b',
      contrastText: '#fff',
    },
    secondary: {
      main: '#5c5c5c',
      contrastText: '#fff',
    },
    strava: {
      main: '#FC4C02',
      contrastText: '#fff',
    },
    spotify: {
      main: '#1DB954',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',

    },
  },
});

theme.typography.h1 = {
  fontSize: '3.5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '4.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '5.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '6.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '7.5rem',
  },
};

theme.typography.h2 = {
    letterSpacing: '-1px',
    fontWeight: 'medium', 
    mt: 2,
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    }
  };

theme.typography.h3 = {
  letterSpacing: '-1px',
  fontWeight: '500', 
  mt: 2,
  fontSize: '1rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
  }
};



function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<>
                                    <Navbar />
                                    <Hero />
                                </>} />
        <Route path="/signup" element={<>
                                    <Navbar />      
                                    <Signup />
                                </>} />
        <Route path="/signin" element={<>
                                    <Navbar />      
                                    <Signin />
                                </>} />
        <Route path="/setup/spotify" element={<PrivateRoute>
                                    <Navbar />      
                                    <SetupSpotify />
                                </PrivateRoute>} />
        <Route path="/setup/strava" element={<PrivateRoute>
                                    <Navbar />      
                                    <SetupStrava />
                                </PrivateRoute>} />
        <Route path="/finished" element={<PrivateRoute>
                                    <Navbar />
                                    <Finished/>
                                </PrivateRoute>} />
        <Route path="/about" element={<>
                                    <Navbar />
                                    <About />
                                </>} />
        <Route path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
      </BrowserRouter> 
      </div>       
    </ThemeProvider>
  );
    
}

export default App;

import Faq from './Faq'


export default function About() {
    return (
    <>
        <h1>
            About ActiveTracks
        </h1>
        <p>
            ActiveTracks is a web application that allows you to connect your Spotify and Strava accounts to create a playlist of your favorite songs from your recent Strava activities.
        </p>
        <Faq/>
    </>
    );
}
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import Skeleton from '@mui/material/Skeleton';
import Switch from "@mui/material/Switch";
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';
import Faq  from "./Faq";

function ResultCard(props) {
  return (
    <Box
      sx={{
        bgcolor: "secondary.main",
        color: "secondary.contrastText",
        borderRadius: "10px",
        m: "10px",
        p: "10px",
      }}
    >
      <Grid2
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid2 item xs={8} sm={8}>
          <Typography variant="h2">{props.primary}</Typography>
        </Grid2>
        <Grid2 item xs={4} sm={4}>
          <Typography variant="h3">{props.secondary}</Typography>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default function Finished(props) {
  const navigate = useNavigate();
  
  const [hasBothTokens, setHasBothTokens] = useState(null);
  const [stravaActivities, setStravaActivities] = useState([]);
  const [spotifyRecents, setSpotifyRecents] = useState([]);
  const [stats , setStats] = useState(null);
  const [isactive, setIsactive] = useState(true);

  const baseurl = "https://xuruz0szwg.execute-api.us-east-1.amazonaws.com";
  const token = localStorage.getItem("token");

  const checkIfActive = async () => {
    const response = await fetch(`${baseurl}/v1/isactive`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      console.log("user is active: ", data.isactive);
      return data.isactive;
    }
    else {
      return null;
    }
  };

  const changeIsActive = async () => {
    console.log("changing isactive to ", !isactive);
    const response = await fetch(`${baseurl}/v1/isactive`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        isactive: !isactive,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      console.log("user is active: ", data.isactive);
      setIsactive(data.isactive);
    }
    else {
      return null;
    }
  };



  const checkIfToken = async () => {
    const response = await fetch(`${baseurl}/v1/hastoken?service=both`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data.has_token) {
        console.log("has both tokens");
        return true;
      } else {
        console.log("does not have both tokens");
        return false;
      }
    } else {
      console.log("error");
      return false;
    }
  };

  useEffect(() => {
    if (token) {
      checkIfToken().then((result) => {
        setHasBothTokens(result);
      });
      checkIfActive().then((result) => {
        setIsactive(result);
      });

      fetch(`${baseurl}/v1/strava/activities`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setStravaActivities(data.activities.slice(0, 1));
          });
        }
      });
      fetch(`${baseurl}/v1/spotify/recent`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setSpotifyRecents(data.tracks.slice(0, 1));
          });
        }
      });
      fetch(`${baseurl}/v1/stats/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setStats(data);
          });
        }
      });
    }
  }, [token]);

  return (
    <Grid2 container direction="column" alignItems="center" >
      {hasBothTokens === true ? (
        <>
      {stats && stats["songs_count"] < 1 ? 
        <Typography variant="h1">All set!</Typography>
       :
        <Typography variant="h1">Welcome back!</Typography>}
      {stats && stats["songs_count"] < 1 ? (
        <Typography variant="h3">The next time you record a workout while listening to music, the songs you listen to will be posted in the description of your Strava activity.</Typography>
      ) : <></>}

      <h3>
      ActiveTracks is {isactive ? <b>ON</b> : <b>OFF</b>}.
      <Switch
        checked={isactive}
        onChange={changeIsActive}
        inputProps={{ "aria-label": "controlled" }}
      />
      {!isactive ? (
        <Typography variant="h3"><em>ActiveTracks won't post songs to your workouts until you turn it on again.<br/>Your listening history will still be saved.</em></Typography>
      ) : <></>}
      </h3>
      <Grid2 item xs={12} sm={6} md={4} sx={{pl:3, pr:3}}>
        {stats && stats["songs_count"] > 0 ? (
          <h3>You've listened to <u>{stats !== null ? stats["songs_count"] : <Skeleton/>}</u> songs over <u>{stats!== null ? stats["activities_count"] : <Skeleton/>}</u>&nbsp;activities</h3>
        ) : <></> }
        <h3>
          Here's your most recent activity:
        </h3>

              {stravaActivities.length > 0 ? (
                   <Box sx={{
                    bgcolor: "strava.main",
                    color: "strava.contrastText",
                    borderRadius: "10px",
                   }}><h3>{stravaActivities[0].name}<br/><em>{stravaActivities[0].type}</em></h3></Box>
              ) : (
                <Skeleton animation="wave" variant="text" sx={{ fontSize: '4rem' }} />
              )}


      </Grid2>
        <Grid2 item xs={12} sm={6} md={4} sx={{pl:3, pr:3}}>
          <h3 >And your most recent song:</h3>
            {spotifyRecents.length > 0 ? (
                  <Box sx={{
                    bgcolor: "spotify.main",
                    color: "spotify.contrastText",
                    borderRadius: "10px",
                  }}><h3>"{spotifyRecents[0].title}" <br/> <em>{spotifyRecents[0].artist}</em></h3></Box>
        ) : (
          <Skeleton animation="wave" variant="text" sx={{ fontSize: '4rem' }} />
        )}
      <h4><em>If these look right, you're good to go.</em></h4>
      </Grid2>
      <Grid2 item xs={12} sm={6} sx={{pl: 3, pr:3}} >
        <Faq/>
      </Grid2>
      </>
      ) : (
        <>
        {hasBothTokens === false ? (
          <>
          <Typography variant="h2">There was a problem signing in to your accounts.</Typography>
          <Button 
                variant="contained"
                component={Link}
                to="/setup/spotify"
                sx={{ 
                    width: {xs: '66%', md: '33%'},
                    color: 'white', 
                    bgcolor: 'primary.main', 
                    fontWeight: 'bold', 
                    fontSize: {xs: '1.5rem', s: '1.5rem'},
                    boxShadow: 5}}>
                Try Again
            </Button>
          </>
        ) : (
        <Typography variant="h1">Checking logins...</Typography>
        )
        }
        </>
      )}
    </Grid2>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";





export default function SignUp() {
  
  const [userExists, setUserExists] = useState(false);

  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email').toLowerCase();
    const password = data.get('password');
    const recaptcha = localStorage.getItem('recaptcha');
    const req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email, password, recaptcha}),
    }
    const response = await fetch('https://xuruz0szwg.execute-api.us-east-1.amazonaws.com/signup', req);
  
    if (response.status === 201) {
      setUserExists(false);
      const res = await response.json();
      localStorage.setItem('token', res.token);
      navigate('/setup/spotify');
    } else if (response.status === 400) {
      setUserExists(true);
    } else {
      alert('Something went wrong. Please try again.');
    }
  };
  

  return (

      <Container component="main" maxWidth="sm" direction="column" alignItems="center">
        <CssBaseline />

        <Box 
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 5
          }}
        >

            <Typography component="h1" variant="h5">
              Sign up
            </Typography>

          <Box component="form"  onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  name="email"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  autoFocus
                  error={userExists}
                  helperText={userExists ? "That email is already in use." : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            <Grid item xs={12}>
            <ReCAPTCHA 
              sitekey='6LeGOpkiAAAAAH0wCpjP6cFJfPgSTz5NEmOXxBez'
              onChange={(value) => localStorage.setItem('recaptcha', value)}
            />
            </Grid>
            <Grid item xs={12}>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                >
              Sign Up
            </Button>
            </Grid>
              <Grid item>
                <Link to="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
              <Grid item>
                <Typography sx={{fontWeight:'bold'}}>
                    "Hey! You didn't need my email before"
                </Typography>
                <Typography >
                    That's true, but it makes it easier to keep your Spotify and Strava logins together, 
                    to keep a record of your listening history, and it's easy for you to remember.
                    I promise I won't sell it or spam you, but if you don't feel like giving it, 
                    you can sign up with a unique username instead.
                </Typography>
              </Grid>
              </Grid>
          </Box>
        </Box>
        
      </Container>

  );
}
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, Redirect } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import strava_button from "./btn_strava_connectwith_orange.svg";
import spotify_icon from "./Spotify_Logo_RGB_White.png";

function StravaDisplay(props) {
  return (
    <>
      <Grid2 item xs={12}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2.5rem", s: "5rem" },
            letterSpacing: "-3px",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Step 2:
          <br />
          Sign in to Strava
        </Typography>
      </Grid2>

      <Grid2 item xs={12}>
        <Box
          component={Link}
          onClick={() => (window.location.href = props.url)}
          sx={{
            borderRadius: 5,
            bgcolor: "strava.main",
            height: "100%",
            display: "grid",
            alignItems: "center",
          }}
        >
          <img src={strava_button} alt="Strava" />
        </Box>
      </Grid2>
    </>
  );
}

export default function SetupStrava() {
  const baseurl = "https://xuruz0szwg.execute-api.us-east-1.amazonaws.com";
  const token = localStorage.getItem("token");
  const [stravaLink, setStravaLink] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const checkIfToken = async () => {
    const response = await fetch(`${baseurl}/v1/hastoken?service=both`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data.has_token) {
        console.log("has both tokens");
        return true;
      } else {
        console.log("does not have both tokens");
        return false;
      }
    } else {
      console.log("error");
      return false;
    }
  };

  const getStravaLink = async () => {
    const response = await fetch(`${baseurl}/v1/authstrava/url`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await response.json();
    setStravaLink(data[0].strava_auth_url);
  };

  useEffect(() => {
    if (token){
        getStravaLink();
    }
    checkIfToken().then((hasToken) => {
        if (hasToken) {
            navigate("/finished");
        }
  })
}, []);

  useEffect(() => {
    if (searchParams.get("code")) {
      fetch(`${baseurl}/v1/authstrava`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          code: searchParams.get("code"),
        }),
      }).then((response) => {
        if (response.status === 200) {
          console.log("success");
        }
      }).then(() => {
          checkIfToken().then((hasToken) => {
              if (hasToken) {
                  navigate("/finished");           
                }
    })
  })
}
}, [searchParams]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 5,
        }}
      >
        <Grid2
          container
          spacing={2}
          sx={{ display: "flex", direction: "column", alignItems: "stretch" }}
        >
          <StravaDisplay url={stravaLink} />
        </Grid2>
      </Box>
    </Container>
  );
}

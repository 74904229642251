import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function Faq() {
    return (
        <Box sx={{
            bgcolor: '#e4e4e4',
            borderRadius: '10px',
        }}>
            <h1>FAQ</h1>
            <h3>
                How do I use ActiveTracks?
            </h3>
            <p>
                Simply listen to music while recording a Strava activity. 
                When you upload your activity, the songs you listened to will be posted in the description of your activity.
            </p>
            <h3>
                Why can't I add songs to old activities? 
            </h3>
            <p>
                Spotify's API can only access the last 50 songs you've listened to. 
                Similarly, if you listen to more than 50 songs during an activity, 
                only the last 50 songs will show up.
            </p>
            <h3>
                I listened to a song, why didn't it show up?
            </h3>
            <p>
                Spotify only counts a song as "recently played" if you listen to it to completion. 
                Unfortunately, this means that if you skip a song, it won't show up.
            </p>
            <h3>
                Can I see my activity stats during a specific song? 
            </h3>
            <p>
                Spotify's API is imprecise about the times when a song starts and finishes. It's enough to know that a song was played during the activity, but not enough to know the exact interval.
            </p>
        </Box>
    )
}
import React, {useEffect} from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode';

export function validateToken(token) {
    try{
        const decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
            return false;
        }
        return true;
    } catch (err) {
        return false;
    }
}


function PrivateRoute(props) {
    const redirectTo = props.redirectTo || '/signin';

    const token = localStorage.getItem('token');
    const location = useLocation();

    if (token) {
        const tokenIsValid = validateToken(token);
        if (tokenIsValid) {
            return props.children;
        }
    }

    return <Navigate to={redirectTo}  replace state={{from: location }} />;
    }


export default PrivateRoute